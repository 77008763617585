import { getAction,postAction } from '../utils/request'
export function listCards(data) {
  return getAction('/web/card/listCards',data)
}

export function getCard(data) {
  return getAction('/web/card/getBook', data)
}

export function deleteCard(data) {
  return getAction('/web/card/deleteCard',data)
}

export function addCard(data) {
  return postAction('/web/card/addCard',data)
}

export function updateCard(data) {
  return postAction('/web/card/updateCard',data)
}
export function listSubjects(data) {
  return getAction('/web/card/getCategory',data)
}
export function addSubject(data) {
  return getAction('/web/card/addCategory',data)
}
export function deleteSubject(data) {
  return getAction('/web/card/deleteCategory',data)
}