<template>
  <div class="wrap">
    <div class="box-top">
      <el-button type="primary" icon="el-icon-circle-plus-outline" size="small" @click="add()">新增</el-button>
      <el-button type="primary" icon="el-icon-edit" size="small" @click="subjectVisible = true">科目</el-button>
    </div>
    <!-- <div style="flex: 1;"> -->
      <el-table :data="tableData" border height="100%" ref="table" style="width: 100%">
        <el-table-column prop="subject" label="科目类别" align="center">
          <template #header>
            <span>科目类别 </span>
            <el-popover width="100" trigger="click" v-model="popVisible">
              <el-radio-group v-model="radio" @change="radioChange">
                <el-radio v-for="item in subjects" :key="item.id" :label="item.id">{{item.cardCategory}}</el-radio>
                <el-button size="mini" @click="handleSearch">确定</el-button>
                <el-button size="mini" @click="reset">重置</el-button>
              </el-radio-group>
              <el-button slot="reference" class="el-icon-search" type="text"></el-button>
            </el-popover>
          </template>
          <template slot-scope="scope">
            {{cardCategoryTxt(scope.row.cardCategory)}}
          </template>
        </el-table-column>
        <el-table-column prop="cardTitle" label="题目" align="center">
        </el-table-column>
        <el-table-column prop="updateTime" label="编辑时间" align="center">
          <template slot-scope="scope">
            {{scope.row.updateTime || scope.row.createTime}}
          </template>
        </el-table-column>
        <el-table-column prop="cardAnswer" label="答案" align="center">
        </el-table-column>
        <el-table-column prop="cardCode" label="激活码" align="center">
          <template slot-scope="scope">
            {{scope.row.cardCode}} <span class="iconfont icon-erweima" @click="handleQR(scope.row)"></span>
          </template>
        </el-table-column>
        <el-table-column prop="options" label="操作" align="center">
          <template slot-scope="scope">
            <el-button @click="editButton(scope.row)" type="text" style="color: #00788b;">编辑</el-button>
            <el-divider direction="vertical"></el-divider>
            <el-button @click="del(scope.row)" type="text" style="color: #b51c1b;">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    <!-- </div> -->
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pageNo"
      :page-sizes="[10, 20, 30, 40]"
      :page-size="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>

    <!-- 新增题目弹窗 -->
    <el-dialog :title="title" :visible.sync="dialogVisible" width="50%" :before-close="handleClose" center :close-on-press-escape="false" :close-on-click-modal="false">
      <study-card-modal v-if="dialogVisible" ref="studyCardModal" :oldForm="form"></study-card-modal>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submit()">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 新增科目弹窗 -->
    <el-dialog title="科目" :visible.sync="subjectVisible" width="30%" :before-close="closeSubject" center :close-on-press-escape="false" :close-on-click-modal="false">
      <subject-modal v-if="subjectVisible" ref="subjectModal"></subject-modal>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="subjectVisible = false">关 闭</el-button>
      </span>
    </el-dialog>
    <el-dialog title="" :visible.sync="QRVisible" width="30%" :before-close="qrClose" center :close-on-press-escape="false" :close-on-click-modal="false">
      <div class="QRBox">
        <h2>{{qrtitle}}</h2>
        <vue-qr :text="imgUrl" :size="250"></vue-qr>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {listCards, listSubjects, deleteCard, addCard, updateCard} from '../../api/studyCard';
import studyCardModal from "./studyCardModal.vue";
import subjectModal from "./subjectModal.vue";
import VueQr from 'vue-qr';
export default {
  name: "activityPublic",
  data() {
    return {
      radio: '',
      popVisible: false,
      mess: "",
      title: '新增题目',
      dialogVisible: false,
      subjectVisible: false,
      tableData:[],
      total: 0,
      pageSize: 10,
      pageNo: 1,
      subjects:[],
      form:{},
      imgUrl: 'https://baidu.com',
      QRVisible: false,
      qrtitle: '',
    };
  },
  components: {
    studyCardModal,
    subjectModal,
    VueQr,
  },
  created(){
    this.getSubjects();
    this.loadData();
  },
  filters:{
    
  },
  methods: {
    loadData(){
      let param = {
        id:this.radio,
        pageNo: this.pageNo,
        pageSize: this.pageSize
      }
      listCards(param).then(res=>{
        if(res.success) {
          if(res.result.records.length == 0 && res.result.total != 0) {
            this.pageNo = res.result.pages;
            this.loadData();
          }else {
          this.tableData = res.result.records;
          this.total = res.result.total;
          this.pageNo = res.result.current;
          this.pageSize = res.result.size;
          }
          this.$refs.table.doLayout();
        }
      })
    },
    async getSubjects(){
      await listSubjects().then(res=>{
        if(res.success) {
          this.subjects = res.result || [];
        }
      })
    },
    cardCategoryTxt(val){
      for(let item of this.subjects) {
        if(item.id ==val) {
          return item.cardCategory
        }
      }
      return "";
    },
    radioChange(e) {
      console.log(e);
    },
    reset() {
      this.popVisible = false;
      this.radio = '';
      this.pageNo = 1;
      this.loadData();
    },
    handleSearch(){
      this.pageNo = 1;
      this.loadData();
      this.popVisible = false;
    },
    add() {
      this.form = {};
      this.title = '新增题目',
      this.dialogVisible = true;
    },
    submit() {
      this.$refs.studyCardModal.getdatas();
      this.$refs.studyCardModal.$refs.form.validate((valid) => {
        let formInfo = this.$refs.studyCardModal.form;
        // console.log(formInfo);
        if (!valid) {
          return;
        } else {
          let request = null;
          if(formInfo.id) {
            request = updateCard(formInfo);
          }else{
            request = addCard(formInfo);
          }
          request.then(res=>{
            if(res.success) {
              this.$message.success(res.message);
              this.dialogVisible = false;
              this.loadData();
            }else {
              this.$message.error(res.message);
            }
          })
        }
      });
    },
    closeSubject() {
      this.subjectVisible = false;
    },
    handleClose() {
      this.dialogVisible = false;
    },
    editButton(row) {
      this.form = row;
      this.title = '编辑题目';
      this.dialogVisible = true;
    },
    del(row) {
      deleteCard({id: row.id}).then(res => {
        if(res.success) {
          this.$message.success(res.message);
          this.loadData();
        }else{
          this.$message.error(res.message);
        }
      })
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.loadData();
    },
    handleCurrentChange(val) {
      this.pageNo = val;
      this.loadData();
    },
    handleQR(e) {
      this.qrtitle = e.cardTitle;
      this.imgUrl = e.cardCode;
      this.QRVisible = true;
    },
    qrClose() {
      this.QRVisible = false;
    }
  },
};
</script>

<style scoped lang="less">
@import "../../assets/css/commonMenu.less";
.el-radio{
  display: block;
  margin: 10px;
}
.el-radio-group{
  padding-left: 10%;
  .el-button{
    background: #333;
    color: white;
    margin-left: 5px;
  }
}
.iconfont{
  font-size: 18px;
  color: skyblue;
}
.QRBox{
  text-align: center;
}
</style>