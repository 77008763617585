<template>
  <div>
    <el-form ref="form" :model="form" :rules="rules" label-width="120px">
      <el-form-item label="科目：" prop="cardCategory">
        <el-select v-model="form.cardCategory" placeholder="请选择">
          <el-option
            v-for="item in subjects"
            :key="item.id"
            :label="item.cardCategory"
            :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="题目：" prop="cardTitle">
        <el-input v-model="form.cardTitle" placeholder="请输入题目"></el-input>
      </el-form-item>
      <el-form-item label="解题步骤：" prop="cardStep">
        <el-input v-model="form.cardStep" placeholder="请输入解题步骤"></el-input>
      </el-form-item>
      <el-form-item label="知识点：" prop="cardSummary">
        <el-input v-model="form.cardSummary" placeholder="请输入知识点"></el-input>
      </el-form-item>
      <el-form-item label="答案：" prop="cardAnswer">
        <el-input v-model="form.cardAnswer" placeholder="请输入答案"></el-input>
      </el-form-item>
      <el-form-item label="图片：" prop="cardImage">
        <!-- <el-upload
          :action="uploadURL"
          list-type="picture-card"
          :headers="headers"
          :limit="5"
          :fileList="fileList"
          :on-remove="handleRemove"
          :on-success="handleAvatarSuccess" :multiple="true">
          <i class="el-icon-plus"></i>
        </el-upload> -->
        <el-upload
          :action="uploadURL"
          list-type="picture-card"
          :multiple="true"
          :headers="headers"
          :limit="5"
          :fileList="fileList"
          :on-preview="handlePreview"
          :on-remove="handleRemove"
          :on-success="handleAvatarSuccess">
          <i class="el-icon-plus"></i>
        </el-upload>
      </el-form-item>
    </el-form>
    <el-dialog :visible.sync="dialogVisible" :modal-append-to-body="false" :append-to-body="true" :close-on-press-escape="false" :close-on-click-modal="false">
      <img width="100%" :src="dialogImageUrl" alt="">
    </el-dialog>
  </div>
</template>

<script>
import {listSubjects } from '../../api/studyCard'
export default {
  name: 'userEdit',
  props:['oldForm'],
  data() {
    return {
      subjects: [],
      uploadURL: baseURL + '/web/file/uploadImage',
      dialogImageUrl:'',
      headers: {
        'X-Access-Token': sessionStorage.getItem('token'),
      },
      rules: {
        cardTitle: [
            { required: true, message: '请输入题目', trigger: 'blur' },
          ],
        cardCategory: [{ required: true, message: '请选择科目', trigger: 'blur' }],
        cardStep: [{ required: true, message: '请上传解题步骤', trigger: 'blur' }],
        cardSummary: [{ required: true, message: '请输入知识点', trigger: 'blur' }],
        cardAnswer: [{ required: true, message: '请输入答案', trigger: 'blur' }],
        cardImage: [{ required: false, message: '请上传图片', trigger: 'blur' }],
      },
      form: {
        cardCategory: '',
        cardTitle: '',
        cardStep: '',
        cardSummary: '',
        cardAnswer: '',
        cardImage: '',
      },
      fileList: [],
      files:[],
      dialogVisible: false,
    }
  },
  mounted(){
    let form = JSON.parse(JSON.stringify(this.oldForm));
    if(form.cardImage) {
      this.fileList = [];
      this.files = [];
      let list = form.cardImage.split(',');
      for(let i = 0;i<list.length;i++) {
        let str = list[i];
        let name = str.substring(str.lastIndexOf('/')+1);
        name = name.substring(0,name.indexOf('_'));
        let obj = {name,url:baseURL+str,fileUrl:str}
        this.fileList.push(obj);
      }
    }
    this.form = form;
    listSubjects().then(res=>{
        if(res.success) {
          this.subjects = res.result || [];
        }
      })
  },
  methods: {
    handleExceed(files, fileList) {
      this.$message.warning(`当前限制选择 5 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
    },
    handleRemove(file, fileList) {
      if(file.fileUrl) {
        for(let i = 0;i<this.fileList.length;i++) {
          if(this.fileList[i].fileUrl === file.fileUrl) {
            this.fileList.splice(i,1);
            break;
          }
        }
      }else{
        if(file.status == 'success' && file.response && file.response.result) {
          for(let i = 0;i< this.files.length;i++) {
            if(file.response.result == this.files[i]) {
              this.files.splice(i,1);
            }
          }
        }
      }
      // debugger;
    },
    handlePreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handleAvatarSuccess(response, file) {
      if(response.success) {
        this.$message.success('上传成功! ');
        this.fileList.push({
          name:file.name,
          url: baseURL + response.result,
          fileUrl: response.result
        });
        this.form.cardImage =  'true';
      }else {
        this.$message.error(res.response.message);
      }
    },
    getdatas(){
      let list = [];
      for(let item of this.fileList) {
        list.push(item.fileUrl);
      }
      for(let item of this.files) {
        list.push(item);
      }
      this.form.cardImage = list.join(',');
    }
  }
}
</script>

<style scoped lang="less">
  .el-select{
    width: 100%;
  }
  /deep/ .avatar-uploader .el-upload {
    border: 1px dashed #333;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  /deep/ .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  /deep/ .avatar-uploader-icon {
    font-size: 28px;
    color: #ccc;
    width: 60px;
    height: 60px;
    line-height: 60px;
    text-align: center;
  }
  /deep/ .avatar {
    width: 60px;
    height: 60px;
    display: block;
  }
</style>