<template>
  <div>
    <el-input v-model="subject" placeholder="请输入科目">
      <template slot="append"><span style="cursor: pointer;color:black;" @click="addSubject">新增科目</span></template>
    </el-input>
    <el-table :data="tableData" style="width: 100%" border height="300">
      <el-table-column prop="cardCategory" label="科目" align="center"></el-table-column>
      <el-table-column prop="action" label="操作" align="center">
        <template slot-scope="scope">
          <el-button @click="del(scope.row)" type="text" style="color: #b51c1b;">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import {listSubjects, addSubject, deleteSubject } from '../../api/studyCard'
export default {
  name: 'subjectModal',
  data() {
    return {
      subject: '',
      tableData: [],
    }
  },
  mounted(){
    this.loadData();
  },
  methods: {
    loadData(){
      listSubjects().then(res=>{
        if(res.success) {
          this.tableData = res.result || [];
        }
      })
    },
    addSubject() {
      let param = {categoryName: this.subject};
      addSubject(param).then(res=>{
        if(res.success) {
          this.subject = '';
          this.loadData();
        }
      })
    },
    del(row){
      deleteSubject({id:row.id}).then(res=>{
        if(res.success) {
          this.loadData();
        }
      })
    }
  },
}
</script>

<style scoped lang="less">
  .el-input{
    margin-bottom: 10px;
  }
</style>