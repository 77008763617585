import { render, staticRenderFns } from "./studyCard.vue?vue&type=template&id=fcab55fe&scoped=true&"
import script from "./studyCard.vue?vue&type=script&lang=js&"
export * from "./studyCard.vue?vue&type=script&lang=js&"
import style0 from "./studyCard.vue?vue&type=style&index=0&id=fcab55fe&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fcab55fe",
  null
  
)

export default component.exports